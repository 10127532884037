import { useGetSiteQuery } from '@apiRtk/sites';
import { CenterY, GapY } from '@components/LayoutUtils';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import { useRedirect } from '@hooks';
import LineChartIcon from '@mui/icons-material/QueryStats';
import { Box, Alert, Typography, Button } from '@mui/material';
import { dictionary } from '@settings/dictionary';
import { useParams } from 'react-router-dom';
import DataExport from './DataExport/DataExport';
import EnergyFlow from './EnergyFlow';
import PerformanceOverTime from './PerformanceOverTime/PerformanceOverTime';

const Overview = () => {
  const params = useParams();
  const siteId = parseInt(params.id as string, 10);
  const redirect = useRedirect();

  const {
    data: siteData,
    isLoading: siteLoading,
    isFetching: siteFetching,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const firstSiteEcd = siteData?.imps[0]?.id;

  const title = siteData?.name ? siteData.name : 'Site overview';

  if (siteError || !siteId) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="Site overview">
        <Box p={5}>
          <Alert variant="filled" color="warning">
            {dictionary.errorNoData}
          </Alert>
        </Box>
      </SiteHeaderWrapper>
    );
  }

  if (siteLoading || siteFetching) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  if (!firstSiteEcd) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName={title}>
        <Box p={5}>
          <Alert variant="filled" color="warning">
            {dictionary.errorNoEcdFound}
          </Alert>
        </Box>
      </SiteHeaderWrapper>
    );
  }

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={title}>
      <Typography variant="h5" pt={4} pb={2}>
        System status
      </Typography>

      <EnergyFlow ecdId={firstSiteEcd} />

      <PerformanceOverTime siteId={siteId} />

      <GapY size={2} />

      <DataExport />

      <CenterY pt={2}>
        <Button
          startIcon={<LineChartIcon />}
          type="button"
          onClick={() => {
            redirect((paths) => paths.siteDataAnalysis(siteId));
          }}
        >
          Go to data analysis
        </Button>
      </CenterY>
    </SiteHeaderWrapper>
  );
};

export default Overview;
