import React from 'react';
import { ProfilesData, useGetProfilesQuery } from '@apiRtk/users';
import { CenterX, Flex, GapY } from '@components/LayoutUtils';
import RoleIcon from '@components/RoleIcon/RoleIcon';
import { useCurrentUserInfo } from '@hooks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Autocomplete,
  Button,
  ListItemText,
  CircularProgress,
  Box,
  Typography,
  AutocompleteRenderInputParams,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { authActions } from '@redux/slices/auth';
import { selectProfileSwitcherSlice, profileSwitcherActions } from '@redux/slices/profileSwitch';
import { useSelector, useDispatch } from 'react-redux';
import { palette } from 'styles/palette';

const renderUsersSelectItem = (profile: ProfilesData[0]) => {
  const { fullname, email, role } = profile;

  return (
    <CenterX gap={1} justifyItems="space-between">
      <RoleIcon role={role} />
      <Box>
        <Typography>{fullname || '-'}</Typography>
        <Typography variant="subtitle2" color="GrayText">
          {email}
        </Typography>
      </Box>
    </CenterX>
  );
};

const renderInput = (params: AutocompleteRenderInputParams) => (
  <TextField
    {...params}
    label="Switch account"
    placeholder="Select user"
    variant="standard"
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      ...params.InputProps,
      sx: {
        '& input': {
          '&::placeholder': {
            opacity: 1,
          },
        },
      },
    }}
  />
);

export const ProfileSwitcher = () => {
  const { sessionActive } = useSelector(selectProfileSwitcherSlice);
  const { fullName, isSuperAdmin, currentUser } = useCurrentUserInfo();

  const dispatch = useDispatch();

  const { data: profiles, isLoading } = useGetProfilesQuery(undefined, {
    skip: !isSuperAdmin || sessionActive,
  });

  if (!isSuperAdmin && !sessionActive) {
    return null;
  }

  const handleOptionClick = (selectedProfile: Nullable<ProfilesData[0]>) => {
    if (selectedProfile) {
      dispatch(profileSwitcherActions.triggerStartSession(selectedProfile?.email));
    }
  };

  const handleButtonClick = () => {
    dispatch(profileSwitcherActions.triggerStopSession());
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box px={2} pb={3}>
      {sessionActive ? (
        <Flex
          flex={1}
          border={`1px solid ${palette.neutral.main}`}
          borderRadius="16px"
          flexDirection="column"
          padding={1}
        >
          <ListItemText
            sx={{ ml: 1 }}
            data-testid="profile-item"
            primary={fullName}
            secondary={currentUser?.email}
          />
          <Button onClick={handleButtonClick} variant="secondaryFade" startIcon={<ArrowBackIcon />}>
            Return to main account
          </Button>

          <GapY size={1} />

          <Button
            onClick={() => dispatch(authActions.logout())}
            variant="secondaryFade"
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </Flex>
      ) : (
        <Autocomplete
          data-testid="autocomplete"
          disablePortal
          options={profiles || []}
          getOptionLabel={(option) => `${option.name}`}
          renderOption={({ key, ...props }, userData) => (
            <li key={key} {...props}>
              {renderUsersSelectItem(userData)}
            </li>
          )}
          fullWidth
          renderInput={renderInput}
          onChange={(_, value) => handleOptionClick(value)}
        />
      )}
    </Box>
  );
};
