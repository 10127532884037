import { useState } from 'react';
import { useGetSiteQuery } from '@apiRtk/sites';
import { ApiErrorPayload } from '@appTypes/api/error';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { RelationsTabs } from '@components/RelationsForm/RelationsTabs';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Alert, CircularProgress, Grid } from '@mui/material';
import EcdList from '@pages/Configuration/Site/UpdateSite/Relations/EcdList';
import { EcdRelationModal } from '@pages/Configuration/Site/UpdateSite/Relations/EcdRelationModal';
import UserList from '@pages/Configuration/Site/UpdateSite/Relations/UserList';
import { UserRelationModal } from '@pages/Configuration/Site/UpdateSite/Relations/UserRelationModal';
import UpdateSiteForm from '@pages/Configuration/Site/UpdateSiteForm';
import { dictionary } from '@settings/dictionary';
import { useParams } from 'react-router-dom';

const SiteConfiguration = () => {
  const params = useParams();
  const id = params.id as string;
  const siteId = parseInt(id, 10);

  const tabs: ConfiguredItem[] = [ConfiguredItem.ECDS, ConfiguredItem.USERS];

  const [userRelationModalOpen, setUserRelationModalOpen] = useState(false);
  const [ecdRelationModalOpen, setEcdRelationModalOpen] = useState(false);
  const [relationsTabVal, setRelationsTabVal] = useState(ConfiguredItem.ECDS);

  const {
    data: siteData,
    isLoading: siteLoading,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const typedSiteError = siteError as ApiErrorPayload | undefined;

  if (Number.isNaN(siteId)) return null;

  const title = siteData?.name ? siteData.name : '-';

  if (siteLoading) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <CircularProgress />
      </SiteHeaderWrapper>
    );
  }

  if (typedSiteError && typeof typedSiteError.data.detail === 'string') {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName={title}>
        <Alert severity="error">
          {typedSiteError && typeof typedSiteError.data.detail === 'string'
            ? typedSiteError.data.detail
            : dictionary.errorCommon}
        </Alert>
      </SiteHeaderWrapper>
    );
  }

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={title}>
      <Grid container spacing={{ xs: 4, lg: 10 }} width="100%" px={2} pt={0}>
        <Grid item xs={12} md={12} lg={4} pt={0}>
          <UpdateSiteForm siteId={siteId} siteData={siteData} />
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <RelationsTabs value={relationsTabVal} tabs={tabs} onValueChange={setRelationsTabVal} />
          {relationsTabVal === ConfiguredItem.ECDS && (
            <EcdList siteData={siteData} onAddRelatedECDs={() => setEcdRelationModalOpen(true)} />
          )}
          {relationsTabVal === ConfiguredItem.USERS && (
            <UserList siteData={siteData} onAddRelatedUser={() => setUserRelationModalOpen(true)} />
          )}
        </Grid>
      </Grid>

      <EcdRelationModal open={ecdRelationModalOpen} setOpen={setEcdRelationModalOpen} />
      <UserRelationModal open={userRelationModalOpen} setOpen={setUserRelationModalOpen} />
    </SiteHeaderWrapper>
  );
};

export default SiteConfiguration;
