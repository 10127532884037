// TODO: Populate this dictionary with all commonly used error messages, titles, and other text strings across the application.
// This centralizes content management, making it easier to maintain consistency.
// Note: In the future, this dictionary can be replaced or extended with a more sophisticated translation solution.

export const dictionary = {
  errorAddEcdToSite: 'Some error occured while adding ECD to site.',
  errorAppBehindFirewall:
    'Podium application was not able to contact our servers, this can be caused by firewall, please try open Podium from another network.',
  errorCommon: 'Some unexpected error occured, please try again later.',
  errorNoData: 'No data available. Please try again later.',
  errorNoEcdFound: 'No ECD found for this site. Please contact support.',
  errorOffline: 'You are currently offline. Please check your internet connection.',
  seriesNoDataInfo: 'No data available in this timeframe',
  errorBookmarkLimitReached: (name: string, maximumCapacity: number) =>
    `Cannot bookmark site ${name}. Maximum capacity of ${maximumCapacity} sites reached.`,
  errorSiteAlreadyBookmarked: (name: string) => `Site ${name} is already bookmarked.`,
  errorSiteNameNotBookmarked: (name: string) => `Site ${name} is not bookmarked.`,
  errorSiteNotBookmarked: 'Site is not bookmarked.',
  successSiteBookmarked: (name: string) => `Site ${name} successfully bookmarked.`,
  successSiteUnbookmarked: (name: string) => `Site ${name} successfully unbookmarked.`,
  selectedSiteNotFound: 'Selected site was not found.',
  graphCommonError: 'An issue occurred while retrieving graph data.',
  preparingGraphData: 'Preparing graph data',
};

export type DictionaryKeys = keyof typeof dictionary;
