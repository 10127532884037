import { useGetSiteQuery } from '@apiRtk/sites';
import { StateFilter } from '@appTypes/helpers/filters';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import { Toolbar } from '@components/Toolbar/Toolbar';
import { useRedirect } from '@hooks';
import { useFiltersTanStack } from '@hooks/useFiltersTanStack';
import { Settings } from '@mui/icons-material';
import { Alert, Box, Button } from '@mui/material';
import { NotificationList } from '@pages/Notifications/NotificationList';
import { dictionary } from '@settings/dictionary';
import { useParams } from 'react-router-dom';

export const SiteNotifications = () => {
  const params = useParams();
  const siteId = parseInt(params.id as string, 10);

  const {
    data: siteData,
    isLoading: siteLoading,
    isFetching: siteFetching,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setStateFilter,
  } = useFiltersTanStack({
    urlMirroringActive: true,
    urlMirroringExcludeArray: ['site_id'],
    sortingActive: false,
    pageSize: 5,
    sortingState: [{ id: 'start', desc: true }],
    defaultSiteId: siteId,
  });

  const redirect = useRedirect();

  const title = siteData?.name ? siteData.name : '-';

  if (siteError || !siteId) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="-">
        <Box p={5}>
          <Alert variant="filled" color="warning">
            {dictionary.errorNoData}
          </Alert>
        </Box>
      </SiteHeaderWrapper>
    );
  }

  if (siteLoading || siteFetching) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={title}>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<Settings />}
        onClick={() => {
          redirect((paths) => paths.siteNotificationRules(siteId));
        }}
      >
        Setup rules
      </Button>
      <>
        <Toolbar
          showSiteSelect={false}
          siteId={siteId}
          searchString={searchString}
          stateFilter={stateFilter || StateFilter.ACTIVE}
          onSetSiteId={() => {}}
          onSearch={setSearchString}
          onSetStateFilter={setStateFilter}
        />
        <NotificationList
          filters={getFilters()}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={(value) => setSorting(value)}
          onPaginationChange={(value) => setPagination(value)}
        />
      </>
    </SiteHeaderWrapper>
  );
};

export default SiteNotifications;
