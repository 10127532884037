import { useState } from 'react';
import { useGetSiteQuery } from '@apiRtk/sites';
import { StateFilter } from '@appTypes/helpers/filters';
import { AddButton } from '@components/Buttons/AddButton';
import { CenterX } from '@components/LayoutUtils';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { Toolbar } from '@components/Toolbar/Toolbar';
import { useRedirect } from '@hooks';
import { useFiltersTanStack } from '@hooks/useFiltersTanStack';
import { Alert, Box, Button } from '@mui/material';
import { EditRulePanel } from '@pages/NotificationRules/EditRule/EditRulePanel';
import { NotificationRulesList } from '@pages/NotificationRules/NotificationRulesList';
import { dictionary } from '@settings/dictionary';
import { useParams } from 'react-router-dom';

const SiteNotificationRules = () => {
  const params = useParams();
  const siteId = parseInt(params.id as string, 10);

  const redirect = useRedirect();

  const {
    data: siteData,
    isLoading: siteLoading,
    isFetching: siteFetching,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setStateFilter,
  } = useFiltersTanStack({
    urlMirroringActive: true,
    urlMirroringExcludeArray: ['site_id'],
    sortingActive: false,
    pageSize: 5,
    sortingState: [{ id: 'site_id', desc: true }],
    state: StateFilter.ALL,
    defaultSiteId: siteId,
  });

  const [ruleIdToEdit, setRuleIdToEdit] = useState<Nullable<number>>(null);
  const [createRuleMode, setCreateRuleMode] = useState(false);

  const formCleanup = () => {
    setRuleIdToEdit(null);
    setCreateRuleMode(false);
  };

  const onRedirectButtonClick = () => {
    redirect((paths) => paths.siteNotifications(siteId));
    formCleanup();
  };

  const title = siteData?.name ? siteData.name : '-';

  if (siteError || !siteId) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Box p={5}>
          <Alert variant="filled" color="warning">
            {dictionary.errorNoData}
          </Alert>
        </Box>
      </SiteHeaderWrapper>
    );
  }

  if (siteLoading || siteFetching) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={title}>
      <TitleBar title="Notification rules" pb={2} />
      <CenterX justifyContent="space-between">
        <Button onClick={onRedirectButtonClick}>Go to notifications list</Button>
        <AddButton
          onClick={() => {
            setRuleIdToEdit(null);
            setCreateRuleMode(true);
          }}
        >
          Add new rule
        </AddButton>
      </CenterX>
      <Toolbar
        showSiteSelect={false}
        siteId={siteId}
        searchString={searchString}
        stateFilter={stateFilter || StateFilter.ACTIVE}
        onSetSiteId={() => {}}
        onSearch={setSearchString}
        onSetStateFilter={setStateFilter}
      />
      <NotificationRulesList
        filters={getFilters()}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
        onEditClick={(id) => {
          setRuleIdToEdit(id);
        }}
      />
      <EditRulePanel
        selectedSiteId={siteId}
        ruleId={ruleIdToEdit}
        createRuleMode={createRuleMode}
        handleFormClose={formCleanup}
      />
    </SiteHeaderWrapper>
  );
};

export default SiteNotificationRules;
