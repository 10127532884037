import { useGetSiteQuery } from '@apiRtk/sites';
import { StateFilter } from '@appTypes/helpers/filters';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import { Toolbar } from '@components/Toolbar/Toolbar';
import { useFiltersTanStack } from '@hooks/useFiltersTanStack';
import { Alert, Box } from '@mui/material';
import { AlarmsList } from '@pages/Alarms/AlarmsList';
import { dictionary } from '@settings/dictionary';
import { useParams } from 'react-router-dom';

const SiteAlarms = () => {
  const params = useParams();
  const siteId = parseInt(params.id as string, 10);

  const {
    data: siteData,
    isLoading: siteLoading,
    isFetching: siteFetching,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setStateFilter,
  } = useFiltersTanStack({
    urlMirroringActive: true,
    urlMirroringExcludeArray: ['site_id'],
    sortingActive: false,
    pageSize: 5,
    sortingState: [{ id: 'last_occurrence', desc: false }],
    defaultSiteId: siteId,
  });

  const title = siteData?.name ? siteData.name : '-';

  if (siteError || !siteId) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Box p={5}>
          <Alert variant="filled" color="warning">
            {dictionary.errorNoData}
          </Alert>
        </Box>
      </SiteHeaderWrapper>
    );
  }

  if (siteLoading || siteFetching) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={title}>
      <Toolbar
        showSiteSelect={false}
        siteId={siteId}
        searchString={searchString}
        stateFilter={stateFilter || StateFilter.ACTIVE}
        onSetSiteId={() => {}}
        onSearch={setSearchString}
        onSetStateFilter={setStateFilter}
      />
      <AlarmsList
        filters={getFilters()}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </SiteHeaderWrapper>
  );
};

export default SiteAlarms;
