import AdminTools from '@pages/AdminTools/AdminTools';
import Alarms from '@pages/Alarms/Alarms';
import Configuration from '@pages/Configuration/Configuration';
import EcdConfiguration from '@pages/Configuration/Ecd/EcdConfiguration';
import CreateOrganization from '@pages/Configuration/Organization/CreateOrganization';
import UpdateOrganization from '@pages/Configuration/Organization/UpdateOrganization';
import CreateSite from '@pages/Configuration/Site/CreateSite';
import UpdateSite from '@pages/Configuration/Site/UpdateSite';
import CreateUser from '@pages/Configuration/User/CreateUser';
import UpdateUser from '@pages/Configuration/User/UpdateUser';
import ForgotPassword from '@pages/ForgotPassword/ForgotPassword';
import Login from '@pages/Login/Login';
import NotificationRules from '@pages/NotificationRules/NotificationRules';
import Notifications from '@pages/Notifications/Notifications';
import SiteAlarms from '@pages/Sites/Alarms/SiteAlarms';
import SiteConfiguration from '@pages/Sites/Configuration/SiteConfiguration';
import DataAnalysis from '@pages/Sites/DataAnalysis/DataAnalysis';
import DataAnalysisNew from '@pages/Sites/DataAnalysisNew/DataAnalysisNew';
import SiteNotificationRules from '@pages/Sites/NotificationRules/SiteNotificationRules';
import SiteNotifications from '@pages/Sites/Notifications/SiteNotifications';
import Overview from '@pages/Sites/Overview/Overview';
import Sites from '@pages/Sites/Sites';
import { paths } from 'paths';

export const routes = [
  { path: paths.login, Component: Login, authRequired: false },
  {
    path: paths.forgotPassword,
    Component: ForgotPassword,
    authRequired: false,
  },
  {
    path: paths.verifyCode,
    Component: ForgotPassword,
    authRequired: false,
  },
  { path: paths.adminTools, Component: AdminTools, isPaddingActive: false },
  { path: paths.sites, Component: Sites, isPaddingActive: false },
  { path: paths.siteOverview, Component: Overview, isPaddingActive: false },
  /*   {
    path: paths.siteDataAnalysis,
    Component: isFeatureFlagEnabled(FEATURE_FLAGS.NEW_DATA_ANALYSIS)
      ? DataAnalysisNew
      : DataAnalysis,
    isPaddingActive: false,
  }, */
  { path: paths.siteDataAnalysis, Component: DataAnalysis, isPaddingActive: false },
  { path: paths.siteDataAnalysisNew, Component: DataAnalysisNew, isPaddingActive: false },
  { path: paths.siteAlarms, Component: SiteAlarms, isPaddingActive: false },
  { path: paths.siteNotifications, Component: SiteNotifications, isPaddingActive: false },
  { path: paths.siteNotificationRules, Component: SiteNotificationRules, isPaddingActive: false },
  { path: paths.siteConfiguration, Component: SiteConfiguration, isPaddingActive: false },
  { path: paths.alarms, Component: Alarms, isPaddingActive: false },
  { path: paths.notifications, Component: Notifications, isPaddingActive: false },
  { path: paths.notificationRules, Component: NotificationRules, isPaddingActive: false },
  { path: paths.configuration, Component: Configuration, isPaddingActive: false },
  { path: paths.configurationUserNew, Component: CreateUser },
  { path: paths.configurationUser, Component: UpdateUser },
  { path: paths.configurationEcd, Component: EcdConfiguration },

  {
    path: paths.configurationOrganizationNew,
    Component: CreateOrganization,
  },
  { path: paths.configurationOrganization, Component: UpdateOrganization },
  { path: paths.configurationSiteNew, Component: CreateSite },
  { path: paths.configurationSite, Component: UpdateSite },
].map((route) => ({ authRequired: true, ...route }));
