import { useEffect, useState } from 'react';
import { SiteIdName } from '@appTypes/models/site.dto';
import { Box, Typography } from '@mui/material';
import { getBookmarkedSitesService } from '@services/bookmarkedSites';
import { MAX_BOOKMARKED_SITES } from '@settings/config';
import { SitesNavList } from './SitesNavList/SitesNavList';

export const DashboardsNavItem = () => {
  const bookmarkedSitesService = getBookmarkedSitesService(MAX_BOOKMARKED_SITES);

  const [bookmarkedSites, setBookmarkedSites] = useState<SiteIdName[]>(
    bookmarkedSitesService.getBookmarkedSites(),
  );

  useEffect(() => {
    const handleBookmarkedSiteChange = () => {
      setBookmarkedSites(bookmarkedSitesService.getBookmarkedSites());
    };

    window.addEventListener('bookmarked-sites-changed', handleBookmarkedSiteChange);
    return () => window.removeEventListener('bookmarked-sites-changed', handleBookmarkedSiteChange);
  }, [bookmarkedSitesService, bookmarkedSites]);

  return (
    <Box pl={0} pr={0} data-cy="bookmarked-sites">
      {(() => {
        if (!bookmarkedSites || bookmarkedSites.length === 0) {
          return (
            <Typography ml={3}>There are no bookmarked sites for the current user.</Typography>
          );
        }

        return (
          <SitesNavList
            maxItemsVisible={MAX_BOOKMARKED_SITES}
            sites={bookmarkedSites}
            showSearchBox={false}
          />
        );
      })()}
    </Box>
  );
};
