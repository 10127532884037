import { useState } from 'react';
import { StateFilter } from '@appTypes/helpers/filters';
import { AddButton } from '@components/Buttons/AddButton';
import { CenterX } from '@components/LayoutUtils';
import { PageWrapper } from '@components/PageWrapper/PageWrapper';
import { Toolbar } from '@components/Toolbar/Toolbar';
import { useRedirect } from '@hooks';
import { useFiltersTanStack } from '@hooks/useFiltersTanStack';
import { Button } from '@mui/material';
import { EditRulePanel } from './EditRule/EditRulePanel';
import { NotificationRulesList } from './NotificationRulesList';

const NotificationRules = () => {
  const redirect = useRedirect();

  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    siteId,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setSiteId,
    setStateFilter,
  } = useFiltersTanStack({
    urlMirroringActive: true,
    sortingActive: false,
    pageSize: 5,
    sortingState: [{ id: 'site_id', desc: true }],
    state: StateFilter.ALL,
  });

  const [ruleIdToEdit, setRuleIdToEdit] = useState<Nullable<number>>(null);
  const [createRuleMode, setCreateRuleMode] = useState(false);

  const formCleanup = () => {
    setRuleIdToEdit(null);
    setCreateRuleMode(false);
  };

  const onRedirectButtonClick = () => {
    redirect((paths) => paths.notifications);
    formCleanup();
  };

  return (
    <PageWrapper title="Notification rules">
      <CenterX justifyContent="space-between">
        <Button onClick={onRedirectButtonClick}>Go to notifications list</Button>
        <AddButton
          onClick={() => {
            setRuleIdToEdit(null);
            setCreateRuleMode(true);
          }}
        >
          Add new rule
        </AddButton>
      </CenterX>
      <Toolbar
        showSiteSelect={false}
        siteId={siteId}
        searchString={searchString}
        stateFilter={stateFilter || StateFilter.ACTIVE}
        onSetSiteId={setSiteId}
        onSearch={setSearchString}
        onSetStateFilter={setStateFilter}
      />
      <NotificationRulesList
        filters={getFilters()}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
        onEditClick={(id) => {
          setRuleIdToEdit(id);
        }}
      />
      <EditRulePanel
        ruleId={ruleIdToEdit}
        createRuleMode={createRuleMode}
        handleFormClose={formCleanup}
      />
    </PageWrapper>
  );
};

export default NotificationRules;
